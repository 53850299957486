<template>
    <div>
        <w-data-table
            v-if="collection.data"
            outlined
            :loading="loading"
            :headers="headers"
            :items="collection.data"
            :filter-by="filterBy"
            :row-click="onRowClick"
        >
            <template v-if="isEditable" #actions>
                <w-btn-action
                    :text="$trans('Add teacher')"
                    icon="ADD"
                    @click="isAsidedrawer = true"
                />
            </template>
            <template #[`item.last_name`]="{item}">
                <member-full-name
                    :item="item"
                />
            </template>
            <!-- actions -->
            <template #[`item.actions`]>
                <w-icon-next color="primary"/>
            </template>
        </w-data-table>
        <w-aside-drawer
            v-model="isAsidedrawer"
            :title="asideTitle"
        >
            <div class="pa-4">
                <teacher-create-form
                    :department-uuid="department.uuid"
                    @submit="onSubmit"
                />
            </div>
        </w-aside-drawer>
    </div>
</template>

<script>
import { getTeachersCollection } from '@/apps/school/api/teachers'
import MemberFullName from '@/components/Member/MemberFullName'
import TeacherCreateForm from '@apps/school/components/Teacher/TeacherForm'

export default {
    name: 'TeachersCollection',
    components: {
        TeacherCreateForm,
        MemberFullName
    },
    props: {
        uuid: String
    },
    computed: {
        isEditable() {
            return this.$auth.isAssistant()
        },
        asideTitle() {
            return this.$trans('Add teacher')
        },
        api() {
            return getTeachersCollection
        },
        department() {
            return this.$store.getters['school/department'](this.$route.params.uuid)
        },
        breadcrumbs() {
            return [
                {
                    text: this.$trans('Teachers'),
                    to: { name: 'teachers' }
                } // {
                //     text: this.department.title
                // }
            ]
        },
        headers() {
            return [
                {
                    text: this.$trans('Full name'),
                    value: 'last_name',
                },
                {
                    text: this.$trans('Email'),
                    value: 'email'
                },
                {
                    text: this.$trans('Phone'),
                    value: 'phone'
                },
                {
                    value: 'actions',
                    sortable: false,
                    align: 'right'
                }
            ]
        }
    },
    data() {
        return {
            isAsidedrawer: false,
            loading: false,
            collection: [],
            filterBy: [ 'last_name', 'first_name', 'middle_name', 'email', 'phone' ]
        }
    },
    mounted() {
        this.$nextTick(async () => {
            await this.fetchMembers()
        })
    },
    methods: {
        async fetchMembers(payload) {
            this.loading = true

            payload = payload || {}
            payload.department = this.uuid

            await this.api(payload)
                .then(response => {
                    this.collection = response.data
                    this.loading = false
                })
        },
        onRowClick(item) {
            this.$router.push({
                name: 'departments.teachers.profile',
                params: { tuuid: item.uuid, uuid: this.uuid }
            })
        },
        onSubmit() {
            this.isAsidedrawer = false
            this.fetchMembers()
        }
    }
}
</script>

<style lang="scss">

</style>
